<template>
  <swiper
    :slides-per-view="'auto'"
    :space-between="30"
    :navigation="true"
    :breakpoints="{
      '@0.00': {
        spaceBetween: 10,
      },
      '@0.75': {
        spaceBetween: 20,
      },
      '@1.00': {
        spaceBetween: 20,
      },
      '@1.50': {
        spaceBetween: 20,
      },
    }"
    :modules="modules"
    class="apartmentSlider relative"
    @slide-change="handleSliderChange"
  >
    <swiper-slide v-for="(object, i) in apartments" :key="object + i">
      <a
        href="https://www.ersta25.se/alla-bostader/"
        class="w-full flex flex-col justify-between h-full pb-5 group"
        @click="selectApartment(i)"
      >
        <div
          class="pb-8"
          :class="{ 'opacity-35': object.status === 'BOOKED' || object.status === 'SOLD' }"
        >
          <div class="slider-heading py-4">
            <div class="flex flex-row pl-8 md:pl-4 pr-4 pb-1 items-center w-full">
              <h2 class="hidden">{{ object.tagline }}/25</h2>
              <p>{{ object.rooms }} ROK</p>
              <span class="stroke"></span>
              <div v-if="object.floor === 0" class="col-span-2">
                <p>Entréplan</p>
              </div>
              <div v-else class="col-span-2">VÅN {{ object.floor }}</div>
              <span class="stroke"></span>
              <p>{{ object.size }} KVM</p>
            </div>
            <p v-if="object.tagline === '1'" class="pl-8 md:pl-4 pr-4 price">
              PRIS: Fås på begäran
            </p>
            <p v-else-if="object.status !== 'SOLD'" class="pl-8 md:pl-4 pr-4 price">
              PRIS: {{ formatPrice(object.price) }} KR
            </p>
            <p v-else class="pl-8 md:pl-4 pr-4 price">PRIS: -</p>
          </div>
          <div v-if="object.description" class="usp">
            <div
              v-for="(item, index) in limitedDescription(object.description)"
              :key="index"
              class="tagline pl-8 md:pl-4 pr-4 py-3"
            >
              {{ item.trim() }}
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-end pr-5 pt-2 w-full relative">
          <div v-if="object.status === 'SOLD'" class="absolute left-6 bottom-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
            >
              <g clip-path="url(#clip0_2000_11)">
                <g filter="url(#filter0_d_2000_11)">
                  <path
                    d="M18.2349 34.8915C27.4166 34.8915 34.8599 27.4233 34.8599 18.2109C34.8599 8.99843 27.4166 1.53027 18.2349 1.53027C9.05313 1.53027 1.60986 8.99843 1.60986 18.2109C1.60986 27.4233 9.05313 34.8915 18.2349 34.8915Z"
                    fill="#BE1622"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_2000_11"
                  x="0.189863"
                  y="0.110273"
                  width="38.93"
                  height="39.0411"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1.42" dy="1.42" />
                  <feGaussianBlur stdDeviation="1.42" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.113725 0 0 0 0 0.113725 0 0 0 0 0.105882 0 0 0 0.54 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2000_11"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2000_11"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_2000_11">
                  <rect width="38" height="38" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <img
            :src="img + '/img/apartment/ersta-' + (i + 1) + '.png'"
            alt=""
            class="max-w-[170px]"
            :class="{ 'opacity-35': object.status === 'BOOKED' || object.status === 'SOLD' }"
          />
        </div>
      </a>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import axios from 'axios'

export default {
  components: {
    Swiper,
    SwiperSlide
  },

  props: ['url', 'img'],
  data() {
    return {
      apartments: [],
      modules: [Navigation]
    }
  },
  mounted() {
    this.fetchApartments()
  },
  methods: {
    limitedDescription(description) {
      return description.split(',').slice(0, 6)
    },
    limitedBulletList(bulletList) {
      return bulletList.slice(0, 6)
    },
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    selectApartment(index) {
      const selectedTagline = this.apartments[index].tagline
      sessionStorage.setItem('selectedApartmentTagline', selectedTagline)
      console.log(index)
    },
    handleSliderChange(index) {
      sessionStorage.setItem('selectedApartmentIndex', index)
    },
    fetchApartments() {
      const token =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJvaG15LWZvcm0ifQ._okybOBNTGT4G27t63QmbJPEk__x8GgRC8xnDENHmb8'
      axios
        .get('https://wallinbostad.rebla.se/api/v1/projects/14', {
          headers: {
            Authorization: `Bearer ${token}`,
            token: token
          }
        })
        .then((response) => {
          this.apartments = response.data.properties || []
          this.apartments.sort((a, b) => Number(a.tagline) - Number(b.tagline)) // Sort the data here
        })
        .catch((error) => {
          console.error('Error fetching apartments:', error)
        })
    }
  }
}
</script>

<style>
.sold-object {
  color: rgba(0, 0, 0, 0.35);
}

.sold-object .slider-heading {
  border-bottom: 3px solid rgba(0, 0, 0, 0.35) !important;
}

.sold-object .tagline {
  border-color: rgba(0, 0, 0, 0.35) !important;
}

.sold-object .stroke {
  background-color: rgba(0, 0, 0, 0.35) !important;
}
</style>
