<template>
  <div
    class="menu fixed w-full top-0"
    :class="[{ 'h-screen bg-beige': isMenuOpen }, { 'bg-beige': isScrolled }]"
  >
    <nav class="h-full flex flex-col">
      <div class="border-b-[3px] border-black">
        <div
          :class="[
            'wrapper',
            'px-5',
            'md:px-12',
            'flex',
            'flex-row',
            'justify-between',
            { 'py-8': !isScrolled, 'py-4': isScrolled },
          ]"
        >
          <a :href="url">
            <img :src="logo" class="max-w-[38px] md:max-w-[62px]" alt="Wallin logo" />
          </a>
          <div class="flex flex-row gap-2 md:gap-4 md:pr-4">
            <a
              href="https://www.erstapenthouse.se/"
              target="_blank"
              class="hidden md:block md:mr-4"
            >
              <button class="btn">penthouse</button>
            </a>
            <a href="https://www.ersta25.se/intresseanmalan" class="hidden md:block">
              <button class="btn">INTRESSEANMÄLAN</button>
            </a>
            <button class="ml-4" @click="toggleMenu">
              <svg
                v-if="!isMenuOpen"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="33"
                viewBox="0 0 37 33"
                fill="none"
              >
                <line y1="32" x2="37" y2="32" stroke="black" stroke-width="2" />
                <line y1="17" x2="37" y2="17" stroke="black" stroke-width="2" />
                <line y1="1" x2="37" y2="1" stroke="black" stroke-width="2" />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="33"
                viewBox="0 0 37 33"
                fill="none"
              >
                <line x1="0" y1="0" x2="37" y2="33" stroke="black" stroke-width="2" />
                <line x1="0" y1="33" x2="37" y2="0" stroke="black" stroke-width="2" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <ul v-if="isMenuOpen" class="heading-md dropdown flex-1 overflow-auto h-screen pb-12">
        <li class="md:hidden p-4 flex flex-col gap-4 border-b-[3px] border-black">
          <a href="https://www.erstapenthouse.se/" target="_blank" class="md:mr-4">
            <button class="btn">penthouse</button>
          </a>
          <a href="https://www.ersta25.se/intresseanmalan" class="">
            <button class="btn">INTRESSEANMÄLAN</button>
          </a>
        </li>
        <li v-for="(link, i) in links" :key="i" class="border-b border-black">
          <div
            v-if="link.child_links"
            class="flex flex-col md:flex-row justify-center items-stretch"
          >
            <div class="w-full md:w-1/2 border-b border-black self-start flex flex-row justify-end">
              <p class="w-full max-w-[720px] px-5 md:px-12 py-6 md:py-8">{{ link.link.title }}</p>
            </div>
            <ul class="flex flex-col w-full md:w-1/2">
              <li
                v-for="(child, index) in link.child_links"
                :key="index"
                class="md:pl-4 dropdown-links border-b last:border-b-[2px] md:last:border-b-0 border-black flex flex-row justify-start py-6 md:py-8 bg-beige-200 hover:bg-white"
              >
                <a :href="child.lank.url" class="w-full max-w-[720px] px-5 md:px-0">
                  {{ child.lank.title }}
                </a>
              </li>
            </ul>
          </div>
          <a v-else :href="link.link.url" class="">
            <div class="hover:bg-beige-200">
              <p class="wrapper px-5 md:px-12 py-6 md:py-8">{{ link.link.title }}</p>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['links', 'logo', 'url'],
  data() {
    return {
      isMenuOpen: false,
      isScrolled: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    handleScroll() {
      if (!this.isMenuOpen) {
        this.isScrolled = window.scrollY > 0
      }
    }
  }
}
</script>

<style>
.menu {
  z-index: 50;
}
.dropdown {
  overflow: auto;
}
</style>
